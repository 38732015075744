import React from "react";

import Layout from "../components/layout";
import Flags from "../components/flagGrid";

export default function Home({ location }) {
  return (
    <Layout location={location}>
      <div className="card p-3">
        <div className="row">
          <div className="col-md">
            <h2>
              Vous voulez appeler un numéro court ou surtaxé depuis l'étranger ?
            </h2>
            <br />
            <p>
              Beaucoup de services utilisent des numéros spéciaux joignables
              uniquement depuis la France. <br />
              <br />
              appelerdeletranger.com est la première solution permettant de
              joindre tous les numéros depuis l’étranger.
            </p>
          </div>
        </div>
      </div>
      <Flags />
    </Layout>
  );
}
